import Eliza from "components/ui/Eliza";
import bg from "assets/images/bg/bg-game-capacita.jpg";
import Button from "components/ui/Button";
import LogoOCB from "components/ui/LogoOCB";
import { useEffect, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { getWinners } from "services/lottery.service";
import { formatCNPJ } from "services/utils";
import { obfuscateEmail } from "utils/helpers";
import LotteryDisplay from "./display";
import "./styles.scss";
import LogoJornada from "components/ui/LogoJornada";
import { searchCooperado } from "services/jornada.service";

export default function Lottery() {
	const [winners, setWinners] = useState<any[]>();
	const [canRetry, setCanRetry] = useState(false);
	const [loading, setLoading] = useState(false);

	async function handleRunLottery() {
		try {
			setCanRetry(false);
			setLoading(true);

			const params = new URLSearchParams(window.location.search);
			const isRandom: boolean = !!params.get("random");
			let winnersList = await getWinners("EucgEEK", isRandom);

			const getWinnerDetails = winnersList.map(async (winner: any) => {
				try {
					return await searchCooperado(winner.extra.cnpj);
				} catch (e) {
					console.log(e);
					return new Promise((resolve) =>
						resolve({ nome_fantasia: "Não encontrado" })
					);
				}
			});

			const winners: any = await Promise.all(getWinnerDetails);

			if (winners && winners.length)
				winnersList = winnersList.map((winner: any, index: number) => {
					console.log(winners);

					return {
						...winner,
						extra: {
							...winner.extra,
							nome_fantasia: winners[index][0].nome_fantasia,
						},
					};
				});

			setWinners(winnersList);
			setLoading(false);
		} catch (e) {}
	}

	useEffect(() => {
		if (winners) {
			setTimeout(() => {
				setCanRetry(true);
			}, 2000);
		}
	}, [winners]);

	return (
		<div
			id="lottery"
			className="relative w-full h-full flex items-center justify-center"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<div className="absolute top-20 right-[600px] z-20">
				<LogoJornada className="w-[200px] h-[200px]" />
			</div>

			<div className="relative w-[1000px] bg-white bg-opacity-90 backdrop-blur rounded-3xl p-16 border flex flex-col gap-10 text-center font-bold ml-[500px]">
				<h1 className="text-6xl">SORTEIO DO BRINDE JORNADA COOP</h1>
				{winners && !loading ? (
					<div className="space-y-4">
						{winners.map((winner: any) => (
							<div
								key={winner._id}
								className="flex overflow-hidden rounded-full border-emerald-500 border-2"
							>
								<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
									<div>
										<LotteryDisplay
											finalName={winner.name.toUpperCase().split(" ")[0]}
											wordLength={8}
											waitTime={2000}
											className={"text-[3rem]"}
										/>
										<LotteryDisplay
											finalName={`${
												winner.extra.nome_fantasia ||
												formatCNPJ(winner.extra.cnpj)
											}`}
											wordLength={5}
											waitTime={2000}
											className={"text-[2rem]"}
										/>
										<LotteryDisplay
											finalName={`${obfuscateEmail(winner.email)}`}
											wordLength={5}
											waitTime={2000}
											className={"text-[2rem] break-all"}
										/>
									</div>
								</div>
								<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
									<LotteryDisplay
										finalName={winner.extra.lottery}
										wordLength={5}
										waitTime={2000}
									/>
								</div>
							</div>
						))}
						{/* <div className="text-8xl flex items-center justify-center p-10 rounded-full border-emerald-500 border-2">
							<span className="leading-none text-emerald-500">
								<LotteryDisplay
									finalName={winner.extra.lottery}
									wordLength={6}
									waitTime={2000}
								/>
							</span>
						</div> */}
					</div>
				) : null}
				{loading ? (
					<div className="space-y-4">
						<div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div>
						{/* <div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div> */}
						{/* <div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div> */}
					</div>
				) : null}
				{!winners && !loading ? (
					<Button onClick={handleRunLottery} className="text-4xl p-10">
						SORTEAR UM GANHADOR
					</Button>
				) : null}
				{canRetry && !loading ? (
					<Button
						onClick={handleRunLottery}
						className="text-4xl p-5 absolute -top-8 -right-8 ring ring-white shadow-xl"
					>
						<AiOutlineReload size={36} />
					</Button>
				) : null}
			</div>
			<Eliza name="stars" className="eliza animate-in slide-in-from-left-20" />
			<LogoOCB className="logo-ocb" white />
		</div>
	);
}
