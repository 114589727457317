import AnimatedText from "components/ui/AnimatedText";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatCNPJ } from "services/utils";

function LoginSuccess() {
	const { user } = useAuth();
	const { coopInfo, loadCoopData } = useCoop();

	const navigate = useNavigate();

	const handleOnAnimationCompleted = () => {
		// navigate("/onboarding");
	};

	useEffect(() => {
		if (user) {
			loadCoopData(formatCNPJ(user.extra.cnpj));
		}

		if (coopInfo) {
			setTimeout(() => {
				navigate("/onboarding");
			}, 2000);
		}
	}, [user, coopInfo, loadCoopData, navigate]);

	return (
		<>
			<AnimatedText>
				<p className="text-center text-blue-dark text-6xl m-5 font-bold">
					Olá, {user?.name.toUpperCase()}!
				</p>

				{coopInfo ? (
					<h1 className="text-center text-blue-dark mb-2 text-5xl">
						ACESSO CONFIRMADO:
						<br />
						<span className="text-7xl font-bold">
							{coopInfo?.nome_fantasia}
						</span>
					</h1>
				) : null}

				{!coopInfo ? (
					<p className="text-center text-blue-dark text-5xl mt-4">
						Enviando coordenadas da Jornada Coop...
					</p>
				) : null}

				<div className="text-center w-1/2 text-blue-dark text-5xl border-4 border-blue-dark border-dashed rounded-lg p-1 m-10 mx-auto">
					<motion.div
						initial={{
							scaleX: 0,
						}}
						animate={{
							scaleX: 1,
						}}
						transition={{
							duration: 1,
							delay: 1,
						}}
						className="w-full p-5 bg-blue-dark rounded-lg origin-left"
						onAnimationComplete={() => handleOnAnimationCompleted()}
					/>
				</div>
			</AnimatedText>
		</>
	);
}

export default LoginSuccess;
