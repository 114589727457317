export const slugify = (
	input: (string | number)[] | string,
	separator = "-"
): string => {
	const value = Array.isArray(input) ? input.join(" ") : input;

	return value
		.normalize("NFD") // split an accented letter in the base letter and the acent
		.replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
		.replace(/\s+/g, separator); // separator
};

let formatter = Intl.NumberFormat("pt-br", { notation: "compact" });

export function formatarFaturamento(valor: number) {
	let value: any = formatter.format(valor);
	value = value.split(" ");
	let unit = value[value.length - 1];

	if (unit === "mi") {
		unit = "Milhões";
	} else if (unit === "bi") {
		unit = "Bilhões";
	}
	return [value[0], unit].join(" ").toLowerCase();
}

export function cnpjValidation(value: any) {
	if (!value) return false;

	// Aceita receber o valor como string, número ou array com todos os dígitos
	const isString = typeof value === "string";
	const validTypes =
		isString || Number.isInteger(value) || Array.isArray(value);

	// Elimina valor em formato inválido
	if (!validTypes) return false;

	// Filtro inicial para entradas do tipo string
	if (isString) {
		// Limita ao máximo de 18 caracteres, para CNPJ formatado
		if (value.length > 18) return false;

		// Teste Regex para veificar se é uma string apenas dígitos válida
		const digitsOnly = /^\d{14}$/.test(value);
		// Teste Regex para verificar se é uma string formatada válida
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

		// Se o formato é válido, usa um truque para seguir o fluxo da validação
		if (digitsOnly || validFormat) true;
		// Se não, retorna inválido
		else return false;
	}

	// Guarda um array com todos os dígitos do valor
	const match = value.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) return false;

	// Elimina inválidos com todos os dígitos iguais
	const items = [...new Set(numbers)];
	if (items.length === 1) return false;

	// Cálculo validador
	const calc = (x: number) => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i];
			sum += n * factor--;
			if (factor < 2) factor = 9;
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12);

	// Valida 1o. dígito verificador
	const digit0 = calc(12);
	if (digit0 !== digits[0]) return false;

	// Valida 2o. dígito verificador
	const digit1 = calc(13);
	return digit1 === digits[1];
}

export function validateEmail(email: string) {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
}

export function obfuscateEmail(email: string) {
	const [name, domain] = email.split("@");
	const nameLength = name.length;
	const obfuscatedName = name.slice(0, 2) + "*".repeat(nameLength - 2);
	return `${obfuscatedName}@${domain}`;
}
